import React from "react";
import theme from "theme";
import { Theme, Icon, Text, Box, Button, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { GiInjustice } from "react-icons/gi";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Home | Justitia Hukuk Danışmanları
			</title>
			<meta name={"description"} content={"Justitia Legal Advisors olarak, kişisel bir dokunuşla olağanüstü hukuki hizmetler sunmaya kararlıyız. "} />
			<meta property={"og:title"} content={"Home | Justitia Hukuk Danışmanları"} />
			<meta property={"og:description"} content={"Justitia Legal Advisors olarak, kişisel bir dokunuşla olağanüstü hukuki hizmetler sunmaya kararlıyız. "} />
			<meta property={"og:image"} content={"https://erimides.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://erimides.com/img/icon.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://erimides.com/img/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://erimides.com/img/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://erimides.com/img/icon.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://erimides.com/img/icon.png"} />
			<meta name={"msapplication-TileImage"} content={"https://erimides.com/img/icon.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="0 0 0 0" quarkly-title="Hero-7">
			<Override slot="SectionContent" width="100%" flex-direction="row" max-width="100%" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				justify-content="space-around"
				lg-flex-direction="column"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					padding="120px 50px 120px 50px"
					lg-width="100%"
					lg-padding="80px 50px 90px 50px"
					sm-padding="80px 25px 90px 25px"
					lg-display="flex"
					lg-flex-direction="column"
				>
					<Icon
						category="gi"
						icon={GiInjustice}
						size="65px"
						color="--primary"
						margin="0px 0px 45px 0px"
					/>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						padding="7px 20px 7px 20px"
						margin="0px 0px 30px 0px"
						border-radius="25px"
						border-width="1px"
						border-style="solid"
						border-color="--color-lightD2"
						sm-flex-direction="column"
						lg-width="fit-content"
					>
						<Text margin="0px 10px 0px 0px" color="#8b9197" font="--base" sm-margin="0px 0 5px 0px">
							Justitia Hukuk Danışmanları'a Hoş Geldiniz
						</Text>
					</Box>
					<Text margin="0px 0px 20px 0px" font="normal 700 56px/1.2 --fontFamily-sans" sm-font="normal 700 42px/1.2 --fontFamily-sans">
						Justitia Hukuk Danışmanları
					</Text>
					<Text margin="0px 0px 50px 0px" font="--lead" color="#8b9197">
						Justitia Hukuk Danışmanları olarak, kişisel bir dokunuşla olağanüstü hukuki hizmetler sunmaya kararlıyız. Deneyimli avukatlardan oluşan ekibimiz, çeşitli hukuki alanlarda uzmanlaşarak hukuki ihtiyaçlarınız için kapsamlı destek sağlamaktadır. Yenilikçi çözümler sunmak için geleneksel hukuk bilgeliğini modern yaklaşımlarla harmanlıyoruz.
					</Text>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						sm-flex-direction="column"
						sm-align-items="stretch"
					>
						<Button
							margin="0px 15px 0px 0px"
							padding="12px 28px 12px 28px"
							background="--color-primary"
							border-radius="8px"
							font="normal 400 17px/1.5 --fontFamily-sans"
							sm-margin="0px 0 15px 0px"
							transition="background-color 0.2s ease 0s"
							border-width="2px"
							border-style="solid"
							border-color="--primary"
							hover-color="--darkL1"
							hover-background="rgba(63, 36, 216, 0)"
							href="/contact"
							type="link"
							text-decoration-line="initial"
						>
							Bize Ulaşın
						</Button>
					</Box>
				</Box>
				<Image
					src="https://erimides.com/img/1.jpg"
					display="block"
					width="50%"
					object-fit="cover"
					height="100%"
					lg-width="100%"
					lg-height="700px"
					sm-height="500px"
				/>
			</Box>
		</Section>
		<Section padding="0 0 0 0" inner-width="100%" quarkly-title="Stages/Steps-1">
			<Box min-width="100px" min-height="100px" display="flex" md-flex-direction="column">
				<Image
					src="https://erimides.com/img/2.jpg"
					display="block"
					width="45%"
					height="100%"
					object-fit="cover"
					md-width="100%"
					md-height="500px"
					sm-height="400px"
				/>
				<Box
					min-width="100px"
					min-height="100px"
					width="55%"
					padding="70px 70px 70px 70px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					justify-content="center"
					lg-padding="50px 50px 50px 50px"
					md-width="100%"
					md-padding="80px 50px 80px 50px"
					sm-padding="80px 25px 80px 25px"
				>
					<Text margin="0px 0px 20px 0px" font="normal 900 42px/1.2 --fontFamily-mono">
						Neden Justitia Hukuk Danışmanlarını Seçmelisiniz?
					</Text>
					<Button
						background="--color-primary"
						color="--light"
						border-width="2px"
						border-style="solid"
						border-color="--color-light"
						padding="12px 30px 12px 30px"
						font="normal 300 16px/1.5 --fontFamily-mono"
						href="/faq"
						type="link"
						text-decoration-line="initial"
					>
						SSS bölümünü okuyun
					</Button>
					<Box
						min-width="100px"
						min-height="100px"
						display="grid"
						grid-template-columns="repeat(2, 1fr)"
						grid-template-rows="repeat(3, 1fr)"
						margin="50px 0px 0px 0px"
						grid-gap="16px 24px"
						sm-grid-template-columns="1fr"
						sm-grid-template-rows="auto"
					>
						<Box
							min-width="100px"
							min-height="100px"
							padding="25px 25px 25px 25px"
							background="#ffffff"
							box-shadow="--m"
						>
							<Box
								min-height={0}
								width="50px"
								height="50px"
								color="--light"
								border-radius="50%"
								background="--color-primary"
								display="flex"
								align-items="center"
								justify-content="center"
								margin="0px 0px 15px 0px"
							>
								<Text margin="0px 0px 0px 0px" quarkly-title="1" font="normal 600 22px/1.2 --fontFamily-sansTrebuchet" transform="rotateZ(-15deg)">
									01
								</Text>
							</Box>
							<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet">
								Çeşitli Hukuk Alanlarında Uzmanlık: İster şirketler hukuku, ister aile meseleleri veya hukuk davaları olsun, çeşitliliğe sahip ekibimiz sizi korur.
							</Text>
						</Box>
						<Box
							min-width="100px"
							min-height="100px"
							padding="25px 25px 25px 25px"
							background="#ffffff"
							box-shadow="--m"
						>
							<Box
								min-height={0}
								width="50px"
								height="50px"
								border-radius="50%"
								background="--color-primary"
								display="flex"
								color="--light"
								align-items="center"
								justify-content="center"
								margin="0px 0px 15px 0px"
							>
								<Text margin="0px 0px 0px 0px" quarkly-title="1" font="normal 600 22px/1.2 --fontFamily-sansTrebuchet" transform="rotateZ(-15deg)">
									02
								</Text>
							</Box>
							<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet">
								Müşteri Odaklı Yaklaşım: İhtiyaçlarınıza öncelik veriyor, özel hukuki durumunuza uygun tavsiye ve stratejiler sunuyoruz.
							</Text>
						</Box>
						<Box
							min-width="100px"
							min-height="100px"
							padding="25px 25px 25px 25px"
							background="#ffffff"
							box-shadow="--m"
						>
							<Box
								min-height={0}
								width="50px"
								height="50px"
								border-radius="50%"
								color="--light"
								background="--color-primary"
								display="flex"
								align-items="center"
								justify-content="center"
								margin="0px 0px 15px 0px"
							>
								<Text margin="0px 0px 0px 0px" quarkly-title="1" font="normal 600 22px/1.2 --fontFamily-sansTrebuchet" transform="rotateZ(-15deg)">
									03
								</Text>
							</Box>
							<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet">
								Şeffaf İletişim: Yasal jargon kafa karıştırıcı olabilir. Her adımda açık ve anlaşılır bir iletişim sağlıyoruz.
							</Text>
						</Box>
						<Box
							min-width="100px"
							min-height="100px"
							padding="25px 25px 25px 25px"
							background="#ffffff"
							box-shadow="--m"
						>
							<Box
								min-height={0}
								width="50px"
								height="50px"
								border-radius="50%"
								background="--color-primary"
								display="flex"
								align-items="center"
								color="--light"
								justify-content="center"
								margin="0px 0px 15px 0px"
							>
								<Text margin="0px 0px 0px 0px" quarkly-title="1" font="normal 600 22px/1.2 --fontFamily-sansTrebuchet" transform="rotateZ(-15deg)">
									04
								</Text>
							</Box>
							<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet">
								Mükemmelliğe Bağlılık: Başarılı davalar ve memnun müvekkillerden oluşan geçmişimiz kendi adına konuşuyor.
							</Text>
						</Box>
						<Box
							min-width="100px"
							min-height="100px"
							padding="25px 25px 25px 25px"
							background="#ffffff"
							box-shadow="--m"
						>
							<Box
								min-height={0}
								width="50px"
								height="50px"
								border-radius="50%"
								background="--color-primary"
								display="flex"
								align-items="center"
								justify-content="center"
								margin="0px 0px 15px 0px"
							>
								<Text
									margin="0px 0px 0px 0px"
									quarkly-title="1"
									font="normal 600 22px/1.2 --fontFamily-sansTrebuchet"
									transform="rotateZ(-15deg)"
									color="--light"
								>
									05
								</Text>
							</Box>
							<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet">
								Etik ve Gizli Hizmet: Tüm müşteri ilişkilerimizde en yüksek etik ve gizlilik standartlarını gözetiyoruz.
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});